import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ManifestContainer from 'containers/ManifestContainer';
import SearchContextToastContainer from 'modules/alerts/containers/SearchContextToastContainer';
import classNames from 'classnames';

export default class ResultItemTile extends PureComponent {
  static propTypes = {
    position: PropTypes.number.isRequired,
    itemId: PropTypes.string.isRequired,
  };

  render() {
    const { itemId, position } = this.props;
    const tileClassNames = classNames(['v-tile', 'tile-result', 'v-result-tile', 's-success']);

    return (
      <div className={tileClassNames}>
        <SearchContextToastContainer itemId={itemId} />
        <ManifestContainer itemId={itemId} analytics={{ internal_location: 'alerts', position }} />
      </div>
    );
  }
}
