import React from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/dialogues/Dialogue';
import { translateElement, translate } from 'instances/i18n';

export default function ManageAlertDialog({ onClose, onClickDelete, alert }) {
  return (
    <Dialog className="v-edit-alert-dialog" onClose={onClose}>
      <div>
        {translateElement(
          <span className="header-title" />,
          'alerts.title.mobile_edit',
          [alert.get('query')],
          false,
        )}
      </div>

      <button className="btn-delete" onClick={onClickDelete} />

      <div className="row">
        <p className="label">{translate('alerts.label.keyword')}</p>
        <p className="keyword">{alert.get('query')}</p>
      </div>
    </Dialog>
  );
}

ManageAlertDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  alert: PropTypes.object.isRequired,
};
